var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"data",attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('button.B0001'),"cancel-title":_vm.$t('button.B0005'),"centered":"","title":_vm.propTitle,"size":"lg","hideHeaderClose":"","no-close-on-backdrop":"","no-close-on-esc":"","body-class":"bodyCustom","footer-class":"footerCustom"},on:{"ok":_vm.onBtnOk,"cancel":_vm.onBtnCancel}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-id"}},[_vm._v(_vm._s(_vm.$t("label.L0009"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"id","rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"text","id":"input-id","readonly":_vm.propType == 'update' ? true : false,"maxLength":"20"},model:{value:(_vm.userinfo.userId),callback:function ($$v) {_vm.$set(_vm.userinfo, "userId", $$v)},expression:"userinfo.userId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-username"}},[_vm._v(_vm._s(_vm.$t("label.L0138"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"name","rules":"required|kor_alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-username","type":"text","autocomplete":"off","maxLength":"10"},model:{value:(_vm.userinfo.userName),callback:function ($$v) {_vm.$set(_vm.userinfo, "userName", $$v)},expression:"userinfo.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.propType == 'update' && !_vm.showLayer),expression:"propType == 'update' && !showLayer"}],staticStyle:{"height":"51.99px"}},[_c('b-col',{staticClass:"cursor-pointer",attrs:{"md":"2"}},[_c('label',{staticClass:"cursor-pointer",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("label.L0010"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-button',{attrs:{"size":"lm","variant":"outline-primary"},on:{"click":function($event){_vm.showLayer = !_vm.showLayer}}},[_vm._v(_vm._s(_vm.$t("label.L0200")))])],1)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLayer),expression:"showLayer"}]},[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v(_vm._s(_vm.$t("label.L0201"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"currentPassword","rules":_vm.showLayer ? 'required|password' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-password","type":"password","maxLength":"20"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.currentPassword),callback:function ($$v) {_vm.$set(_vm.userinfo, "currentPassword", $$v)},expression:"userinfo.currentPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" "+_vm._s(_vm.errorMsg))])]}}])})],1)],1)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLayer),expression:"showLayer"}]},[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v(_vm._s(_vm.$t("label.L0010"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"password","rules":_vm.showLayer ? 'required|password' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-password","type":"password","maxLength":"20"},model:{value:(_vm.userinfo.password),callback:function ($$v) {_vm.$set(_vm.userinfo, "password", $$v)},expression:"userinfo.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-check-pass"}},[_vm._v(_vm._s(_vm.$t("label.L0014"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"paaswordCheck","rules":_vm.showLayer ? 'required|confirmed:password' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-check-pass","type":"password","maxLength":"20"},model:{value:(_vm.userinfo.checkpass),callback:function ($$v) {_vm.$set(_vm.userinfo, "checkpass", $$v)},expression:"userinfo.checkpass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.propType == 'create'),expression:"propType == 'create'"}]},[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v(_vm._s(_vm.$t("label.L0010"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"passwordCreate","rules":{ required: _vm.propType != 'update', password: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-password","type":"password","maxLength":"20"},model:{value:(_vm.userinfo.password),callback:function ($$v) {_vm.$set(_vm.userinfo, "password", $$v)},expression:"userinfo.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-check-pass"}},[_vm._v(_vm._s(_vm.$t("label.L0014"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"paaswordCheckCreate","rules":_vm.propType == 'create'
                    ? 'required|confirmed:password'
                    : 'confirmed:password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-check-pass","type":"password","maxLength":"20"},model:{value:(_vm.userinfo.checkpass),callback:function ($$v) {_vm.$set(_vm.userinfo, "checkpass", $$v)},expression:"userinfo.checkpass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-branchCode"}},[_vm._v(_vm._s(_vm.$t("label.L0015")))])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"kor_alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-branchCode","type":"text","maxLength":"10"},model:{value:(_vm.userinfo.branchCode),callback:function ($$v) {_vm.$set(_vm.userinfo, "branchCode", $$v)},expression:"userinfo.branchCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-tel-num"}},[_vm._v(_vm._s(_vm.$t("label.L0016")))])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"tel","rules":"checkTel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text-left",attrs:{"id":"input-tel-num","type":"text","maxLength":"15"},model:{value:(_vm.userinfo.telNum),callback:function ($$v) {_vm.$set(_vm.userinfo, "telNum", $$v)},expression:"userinfo.telNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-userAuth"}},[_vm._v(_vm._s(_vm.$t("label.L0081"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-button-group',{attrs:{"id":"input-userAuth","size":"lm"}},[_c('b-button',{attrs:{"variant":"outline-primary","pressed":_vm.userinfo.userAuth == 'U' ? true : false},on:{"click":function($event){_vm.userinfo.userAuth = 'U'}}},[_vm._v(_vm._s(_vm.$t("label.L0198")))]),_c('b-button',{attrs:{"variant":"outline-primary","pressed":_vm.userinfo.userAuth == 'A' ? true : false},on:{"click":function($event){_vm.userinfo.userAuth = 'A'}}},[_vm._v(_vm._s(_vm.$t("label.L0199")))])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }