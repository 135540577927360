<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0001')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      size="lg"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      body-class="bodyCustom"
      footer-class="footerCustom"
    >
      <validation-observer ref="simpleRules">
        <!-- <b-form>
          <b-row>
            <b-col md="2">     <label for="input-id">{{$t("label.L0009")}}</label><span class="requiredStar">*</span></b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider  name="id" rules="required|alpha_num" #default="{ errors }">
             
                  <b-form-input
                  autocomplete="off"
                  v-model="userinfo.userId"
                    type="text"
                    id="input-id"
                    :readonly="propType=='update' ? true:false"
                    maxLength="20"
                  />
                  <small class="text-danger" >
                    {{ errors[0]}}
                    </small>
                    </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">     <label for="input-username">{{$t("label.L0138")}}</label><span class="requiredStar">*</span></b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider  name="name"  #default="{ errors }" rules="required|kor_alpha_num">
                  <b-form-input
                  id="input-username"
                  v-model="userinfo.userName"
                    type="text"
                    maxLength="10"
                  />
                  <small class="text-danger" >
                    {{ errors[0]}}
                  </small>
                    </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row style="height:51px" v-show="propType=='update'" >
           <b-col md="3" @click="showLayer=!showLayer"   class="cursor-pointer"> <label for="" class="cursor-pointer" >비밀번호 변경</label> <feather-icon :icon="showLayer ? 'ChevronUpIcon':'ChevronDownIcon'"></feather-icon></b-col>
           <b-col  algin="right">  </b-col>
          </b-row>
          <b-row v-show="showLayer">
          
            <b-col md="2">     <label for="input-password">{{$t("label.L0010")}}</label><span class="requiredStar">*</span></b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider   name="password" :rules="{required:propType != 'update',password:true}" vid="password" #default="{ errors }">
                  <b-form-input
                  id="input-password"
                  v-model="userinfo.password"
                    type="password"
                    maxLength="20"
                  />
                  <small class="text-danger"  >{{ errors[0] }}</small>
                    </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">     <label for="input-check-pass">{{$t("label.L0014")}}</label><span class="requiredStar">*</span></b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider  name="paaswordCheck" :rules="propType =='create' ? 'required|confirmed:password':'confirmed:password'" #default="{ errors }">
                  <b-form-input
                  id="input-check-pass"
                  v-model="userinfo.checkpass"
                    type="password"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-show="propType=='create'">
          
          <b-col md="2">     <label for="input-password">{{$t("label.L0010")}}</label><span class="requiredStar">*</span></b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider   name="password" :rules="{required:propType != 'update',password:true}" vid="password" #default="{ errors }">
                <b-form-input
                id="input-password"
                v-model="userinfo.password"
                  type="password"
                  maxLength="20"
                />
                <small class="text-danger"  >{{ errors[0] }}</small>
                  </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">     <label for="input-check-pass">{{$t("label.L0014")}}</label><span class="requiredStar">*</span></b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider  name="paaswordCheck" :rules="propType =='create' ? 'required|confirmed:password':'confirmed:password'" #default="{ errors }">
                <b-form-input
                id="input-check-pass"
                v-model="userinfo.checkpass"
                  type="password"
                  maxLength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
          <b-row>
            <b-col md="2">
              <label for="input-userAuth">권한</label><span class="requiredStar">*</span>
            </b-col>
            <b-col md="4" style=" padding-top: 7px;">
              <b-form-group>
                  <b-button-group   id="input-userAuth" size="sm">
                    <b-button variant="outline-primary" @click="userinfo.userAuth ='A'" :pressed="userinfo.userAuth =='A'? true:false">관리자</b-button>
                  <b-button variant="outline-primary"  @click="userinfo.userAuth ='U'" :pressed="userinfo.userAuth =='U'? true:false">사용자</b-button>
                  </b-button-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label  for="input-branchCode">{{$t("label.L0015")}}</label>
            </b-col>
            <b-col md="4">
              <b-form-group>
                  <b-form-input
                  id="input-branchCode"
                  v-model="userinfo.branchCode"
                    type="text"
                    maxLength="10"
                  />
              </b-form-group>
            </b-col>
            <b-col md="2"> <label for="input-extension">내선번호</label></b-col>
            <b-col md="4">
              <b-form-group>
                  <b-form-input
                          class="text-right"
                  id="input-extension"
                  v-model="userinfo.extension"
                    type="text"
                    :formatter="numberFormatter"
                    maxLength="10"
                  />
              </b-form-group>
            </b-col>
            <b-col md="2"> <label for="input-tel-num">연락처 </label></b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider name="tel" rules="checkTel" v-slot="{ errors }">

                  <b-form-input
                          class="text-right"
                  id="input-tel-num"
                  v-model="userinfo.telNum"
                  type="text"
                  maxLength="15"
                  />
                  <small class="text-danger"  >{{ errors[0] }}</small>
                    </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
         
        </b-form> -->

        <b-form>
          <b-row>
            <b-col md="2">
              <label for="input-id"
                >{{ $t("label.L0009")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="id"
                  rules="required|alpha_num"
                  #default="{ errors }"
                >
                  <b-form-input
                    autocomplete="off"
                    v-model="userinfo.userId"
                    type="text"
                    id="input-id"
                    :readonly="propType == 'update' ? true : false"
                    maxLength="20"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-username"
                >{{ $t("label.L0138")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="name"
                  #default="{ errors }"
                  rules="required|kor_alpha_num"
                >
                  <b-form-input
                    id="input-username"
                    v-model="userinfo.userName"
                    type="text"
                    autocomplete="off"
                    maxLength="10"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            style="height: 51.99px"
            v-show="propType == 'update' && !showLayer"
          >
            <b-col md="2" class="cursor-pointer">
              <label for="" class="cursor-pointer"
                >{{ $t("label.L0010")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4"
              ><b-button
                size="lm"
                @click="showLayer = !showLayer"
                variant="outline-primary"
                >{{ $t("label.L0200") }}</b-button
              >
            </b-col>
          </b-row>
          <b-row v-show="showLayer">
            <b-col md="2">
              <label for="input-password"
                >{{ $t("label.L0201")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="currentPassword"
                  :rules="showLayer ? 'required|password' : ''"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-password"
                    v-model="userinfo.currentPassword"
                    type="password"
                    @focus="initErrorMsg"
                    maxLength="20"
                  />
                  <small class="text-danger"
                    >{{ errors[0] }} {{ errorMsg }}</small
                  >
                  <!-- <b-card-text class="mb-2" style="color: red">
                    
                  </b-card-text> -->
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2">
               <b-button class="" variant="primary" @click="checkPassword()"> 확인 </b-button>
              </b-col> -->
          </b-row>
          <b-row v-show="showLayer">
            <b-col md="2">
              <label for="input-password"
                >{{ $t("label.L0010")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="password"
                  :rules="showLayer ? 'required|password' : ''"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-password"
                    v-model="userinfo.password"
                    type="password"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-check-pass"
                >{{ $t("label.L0014")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="paaswordCheck"
                  :rules="showLayer ? 'required|confirmed:password' : ''"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-check-pass"
                    v-model="userinfo.checkpass"
                    type="password"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-show="propType == 'create'">
            <b-col md="2">
              <label for="input-password"
                >{{ $t("label.L0010")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="passwordCreate"
                  :rules="{ required: propType != 'update', password: true }"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-password"
                    v-model="userinfo.password"
                    type="password"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-check-pass"
                >{{ $t("label.L0014")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="paaswordCheckCreate"
                  :rules="
                    propType == 'create'
                      ? 'required|confirmed:password'
                      : 'confirmed:password'
                  "
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-check-pass"
                    v-model="userinfo.checkpass"
                    type="password"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <label for="input-branchCode">{{ $t("label.L0015") }}</label>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="kor_alpha_num"
                >
                  <b-form-input
                    id="input-branchCode"
                    v-model="userinfo.branchCode"
                    type="text"
                    maxLength="10"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2"> <label for="input-extension">내선번호</label></b-col>
            <b-col md="4">
              <b-form-group>
                <b-form-input
                  id="input-extension"
                  v-model="userinfo.extension"
                  type="text"
                  :formatter="numberFormatter"
                  maxLength="10"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="2"> <label for="input-tel-num">{{ $t("label.L0016") }}</label></b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="tel"
                  rules="checkTel"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="text-left"
                    id="input-tel-num"
                    v-model="userinfo.telNum"
                    type="text"
                    maxLength="15"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <label for="input-userAuth"
                >{{ $t("label.L0081") }}<span class="requiredStar">*</span></label
              >
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-button-group id="input-userAuth" size="lm">
                  <b-button
                    variant="outline-primary"
                    @click="userinfo.userAuth = 'U'"
                    :pressed="userinfo.userAuth == 'U' ? true : false"
                    >{{ $t("label.L0198") }}</b-button
                  >
                  <b-button
                    variant="outline-primary"
                    @click="userinfo.userAuth = 'A'"
                    :pressed="userinfo.userAuth == 'A' ? true : false"
                    >{{ $t("label.L0199") }}</b-button
                  >
                </b-button-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import cryptoJS from "crypto-js";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  name: "equipPopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButtonGroup,
    BInputGroup,
    BInputGroupAppend,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    propTitle: String,
    propType: String,
  },
  data() {
    return {
      passwordConfirmed: "",
      showLayer: false,
      errorMsg: "", // 기존 비밀번호 체크시 맞지 않는 에러 발생시 저장할 변수
      userinfo: {
        userId: "",
        userName: "",
        currentPassword: "",
        password: "",
        checkpass: "",
        extension: "",
        telNum: "",
        companyCode: "",
        branchCode: "",
        team: "",
        userAuth: "",
      },
      langType: "가",
      authorOptions: [
        {
          text: "관리자",
          data: "A",
        },
        {
          text: "사용자",
          data: "U",
        },
      ],
    };
  },
  methods: {
    async onBtnOk(data) {
      data.preventDefault();
      const success = await this.$refs.simpleRules.validate();

      if (success) {
        var userInfoObj = { ...this.userinfo };
        const crypto = require("crypto");

        if (this.propType === "update" && this.showLayer === true) {
          // 사용중이던 pwd 맞는지 체크
          const encoderCurrentPW = userInfoObj.currentPassword; // 기존 pwd 암호화
          const sha256CurrentPw = crypto
            .createHash("sha256")
            .update(encoderCurrentPW)
            .digest("hex");

          var response = await this.api.checkCurrentPwd(
            userInfoObj.userId,
            sha256CurrentPw
          ); // 기존 비밀번호 체크
          var result = await response.data;
          if (!result.success) {
            // 기존 비밀번호 틀린경우 실행
            var errmss = result.error.message;
            var splitarr = errmss.split(".");
            var tmpmsg = "";
            for (let i = 0; i < splitarr.length - 1; i++) {
              tmpmsg += `${splitarr[i]}.`;
            }
            // this.errorMsg = tmpmsg; // errorMsg : 비번 틀림
            this.errorMsg = "기존 비밀번호가 틀렸습니다. "; // errorMsg : 비번 틀림
            return false;
          } else {
            // 기존 비밀번호 통과
            userInfoObj.currentPassword = sha256CurrentPw; // 기존 비밀번호 암호화
            this.errorMsg = "";
          }
        }

        if (
          this.propType === "create" ||
          (this.propType === "update" && this.showLayer === true)
        ) {
          // 사용자 수정시 비밀번호 수정 안하면 암호화 값 없어서 별도 처리 함
          // 새로 설정한 비밀번호 암호화해서 넘겨주기
          // 새로 사용할 pwd 암호화
          const encoderChangePW = userInfoObj.password; // 비밀번호
          const sha256ChangePw = crypto
            .createHash("sha256")
            .update(encoderChangePW)
            .digest("hex");
          const encoderCheck_pw = userInfoObj.checkpass; //  비밀번호 확인
          const sha256CheckPw = crypto
            .createHash("sha256")
            .update(encoderCheck_pw)
            .digest("hex");

          userInfoObj.password = sha256ChangePw; // 비밀번호
          userInfoObj.checkpass = sha256CheckPw; // 비밀번호 확인
        }

        // if(this.propType=='update'){
        //   await this.$emit("update-obj", this.userinfo);
        // }
        // else{
        //   const promise = await this.$emit("create-obj", this.userinfo)
        // }

        await this.$emit(this.propType + "-obj", userInfoObj);
      }
    },

    initErrorMsg() {
      // 기존 비밀번호 입력시 errorMsg 초기화
      this.errorMsg = "";
    },

    showErrorModal(result) {
      if (!!result) {
        this.$swal({
          heightAuto: false,
          title: result.message,
          icon: "error",
          confirmButtonText: $t("button.B0001"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
          },
          buttonsStyling: false,
        });
      } else {
        this.hideModal();
      }
    },
    hideModal() {
      this.$refs.data.hide();
    },
    onBtnCancel() {},
    updatepopup(data) {
      this.passwordConfirmed = "confirmed:password";
      this.showLayer = false;
      if (data) {
        this.userinfo = { ...data.item };
      }
      this.$refs.data.show();
    },
    createpopup() {
      this.passwordConfirmed = "confirmed:password";
      this.userinfo = {
        userId: "",
        userName: "",
        password: "",
        checkpass: "",
        extension: "",
        telNum: "",
        companyCode: "",
        branchCode: "",
        userAuth: "U",
      };
      this.$refs.data.show();
    },
  },
  mounted() {},
};
</script>
<style scoped>
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}
</style>
<style>
.bodyCustom {
  padding: 1.4rem 0.7rem 0.1rem 0.7rem;
}
.footerCustom {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 14px !important;
}
</style>
