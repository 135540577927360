import { ref, watch, computed, reactive, toRefs } from "@vue/composition-api";
import api from '@/libs/api.js'
// Notification
import { useToast } from "vue-toastification/composition";
import dayjs from "dayjs";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기

export default function useUsersList() {
  const toast = useToast();

  const maintable = ref(null);

  const tableColumns = [
    { key: "idx", label: i18n.t("label.L0055"), thStyle: { width: '6%' } },
    { key: "userId", label: i18n.t("label.L0009"), thStyle: { width: '13%' } },
    { key: "userName", label: i18n.t("label.L0138"), thStyle: { width: '10%' } },
    // { key: "extension", label: '내선 번호' },
    { key: "telNum", label: i18n.t("label.L0016"), thStyle: { width: '10%' } },
    { key: "branchCode", label: i18n.t("label.L0015"), thStyle: { width: '8%' } },
    // { key: "userAuthString", label: '권한', thStyle: { width: '8%' } },
    { key: "modificationDate", label: i18n.t("label.L0079"), thStyle: { width: '14%' }, formatter: (value, key, item) => {
       return new dayjs(item.regDate, "YYYYMMDDHHmmss").add(7,'day').format("YYYY-MM-DD HH:mm:ss");
    },  },
    // { key: "modificationDate", label: '수정일', thStyle: { width: '14%' }, formatter: (value, key, item) => {
    //   return item.modificationDate  ? new dayjs(item.modificationDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ''
    // },  },
    { key: "regDateStr", label: i18n.t("label.L0080"), thStyle: { width: '14%' } , formatter: (value, key, item) => {
      return new dayjs(item.regDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
    }, },
    { key: "lastLoginDate", label: i18n.t("label.L0208"), thStyle: { width: '14%' } },
    { key: "work", label: i18n.t("label.L0064"), thStyle: { width: '14%' } },
  ];
  const rowNum = ref(10);
  const totalRows = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10,50,100];
  const filter = ref("");
  const searchType = ref("userId");
  const getregLocalDate=ref({});
  const getmodLocalDate=ref({});
  const dataMeta = computed(() => {
    const localItemsCount = maintable.value
      ? maintable.value.localItems.length
      : 0;
    return {
      from: rowNum.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: rowNum.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    };
  });

  const refetchData = () => {
    maintable.value.refresh();
  };

  watch([currentPage, rowNum], () => {
    refetchData();
  });

  const getUserList = async () => {

    for (var i = 0; i < tableColumns.length; i++) {
      var userTableColumns = tableColumns[i];
      if(i==0){
        userTableColumns.label = i18n.t("label.L0055");
      }
      if(i==1){
        userTableColumns.label = i18n.t("label.L0009");
      }
      if(i==2){
        userTableColumns.label = i18n.t("label.L0138");
      }
      if(i==3){
        userTableColumns.label = i18n.t("label.L0016");
      }
      if(i==4){
        userTableColumns.label = i18n.t("label.L0015");
      }
      if(i==5){
        userTableColumns.label = i18n.t("label.L0079");
      }
      if(i==6){
        userTableColumns.label = i18n.t("label.L0080");
      }
      if(i==7){
        userTableColumns.label = i18n.t("label.L0208");
      }
      if(i==8){
        userTableColumns.label = i18n.t("label.L0064");
      }
    }

    try {
        const response =  await api.getUserList({
          page : currentPage.value, 
          pageSize : 1000000, 
          // userId: searchType.value =='userId' ? filter.value:"",
          // userName: searchType.value =='userName' ? filter.value:"",
          // telNum: searchType.value =='telNum' ? filter.value:"",
          // branchCode:searchType.value =='branchCode' ? filter.value:"",
          
        })
          const {data}= response.data;
          const   {
            endRecordNum,
            page,
            pageCount,
            pageSize,
            startRecordNum,
            total
          }    = data.paging;
          totalRows.value=total;

          data.userList.forEach((each)=>{
            getregLocalDate.value[each.userId]= each.regDate
            getmodLocalDate.value[each.userId]= each.modificationDate
            if(each.modificationDate){
            each.modificationDate= new dayjs(each.modificationDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") 
            }
            else{
            each.modificationDate= ''
            }
            each.regDate = new dayjs(each.regDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
            if(each.lastLoginDate!=null){
              each.lastLoginDate = new dayjs(each.lastLoginDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
            }
          })

        refetchData();

        return data.userList;
    }
    catch (err) {
      console.log(err)
    }
  }

  const createFunction =async(data)=>{
    try{
        const response = await api.addUser(data);
        return response.data;
    }catch(err){
      console.log('userCreateError', err);
    }
  }
  const updateFunction= async(data)=>{
    try{
      const response = await api.modifyUser(data.userId, data);
      return response.data;
    }catch(err){
      console.log('userUpdateError',err);
    }
        
  }
  const deleteFunction= async(data)=>{
    try{
      const response = await api.removeUser(data);
      return response.data;
    }catch(err){
      console.log('userDeleteError',err);
    }
}
  return {
    createFunction,
    deleteFunction,
    refetchData,
    getUserList,
    tableColumns,
    rowNum,
    totalRows,
    currentPage,
    dataMeta,
    perPageOptions,
    filter,
    searchType,
    maintable,
    updateFunction,
    getregLocalDate,
    getmodLocalDate
  };
}
