<template>
  <div>
    <b-card class="col-12 d-flex flex-column" no-body style="min-height: 85vh">
      <b-row class="my-2">
        <!-- <b-col md="3">
            <label for="" class="mr-2" style="font-size:1rem;">
              검색 조건
            </label>
            <b-button-group >
              <b-button  variant="outline-primary" style="width:85.33px" @click="sortDirection =''" :pressed="sortDirection ==''? true:false">{{$t("label.L0021")}}</b-button>
              <b-button  variant="outline-primary" @click="sortDirection ='A'" :pressed="sortDirection =='A'? true:false">관리자</b-button>
              <b-button  variant="outline-primary" @click="sortDirection ='U'" :pressed="sortDirection =='U'? true:false">사용자</b-button>
            </b-button-group>
          </b-col> -->
        <b-col md="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filterInput"
              :placeholder="$t('message.M0005')"
              v-model="tempfilter"
              type="search"
              @keyup.enter="doFilter"
              style="margin: 0px; height: 3rem;"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="doFilter">{{
                $t("button.B0008")
              }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col align="right">
          <b-button class="addBtn" variant="primary" @click="addUser()">
            {{ $t("label.L0143") }}
          </b-button>
        </b-col>
      </b-row>

      <b-row class="padding-base-12 flex-grow-1">
        <b-table
          sticky-header
          ref="maintable"
          striped
          hover
          responsive
          :items="items"
          :fields="tableColumns"
          :per-page="rowNum"
          :sort-by.sync="userId"
          sort-desc.sync="true"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :current-page="currentPage"
          class="mb-0 text-center"
          show-empty
          :empty-text="$t('message.M0008')"
          :empty-filtered-text="$t('message.M0009')"
          style="max-height: 100%"
        >
          <template #cell(idx)="data">
            {{ rowNum * (currentPage - 1) + (data.index + 1) }}
          </template>

          <template #cell(work)="data">
            <b-button-group size="sm">
              <b-button variant="outline-primary" @click="updateUser(data)">{{
                $t("button.B0006")
              }}</b-button>
              <b-button variant="outline-primary" @click="deleteRow(data)">{{
                $t("button.B0007")
              }}</b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-row>

      <b-row class="my-2" align-v="center" style="bottom: 0; width: 100%;">
        <b-col md="1" class="ml-1">
          <b-form-group class="mb-0">
            <v-select
              id="perPageSelect"
              v-model="rowNum"
              size="sm"
              :options="perPageOptions"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col>
          {{ $t("label.L0021") }} {{ totalRows }} {{ $t("label.L0111") }}
          {{ rowNum * (currentPage - 1) + 1 }} ~
          {{
            rowNum * currentPage < totalRows ? rowNum * currentPage : totalRows
          }}
          {{ $t("label.L0112") }}
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            first-number
            last-number
            :per-page="rowNum"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>

    <user-modal
      ref="update"
      :propTitle="propTitle"
      :propType="propType"
      @update-obj="updateEvent"
    />
    <user-modal
      ref="create"
      :propTitle="propTitle"
      :propType="propType"
      @create-obj="createEvent"
    />
  </div>
</template>
<script>
import {
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BButtonGroup,
  BCard,
} from "bootstrap-vue";

import userModal from "./components/userPopup.vue";
import userInterface from "./userInterface";

import vSelect from "vue-select";
export default {
  name: "admin_userinquiry",
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BPagination,
    BTable,
    BButtonGroup,
    BCard,
    vSelect,
    userModal,
  },
  data() {
    return {
      copyItmes: [],
      adminTypeItems: [],
      userTypeItems: [],
      sortDirection: "",
      userId: "userId",
      tempfilter: "",
      filterOn: [],
      filter: "",
      items: [],
      propTitle: "",
      propType: "",
      searchOptions: [
        { text: this.$t("label.L0115"), data: "userId" },
        { text: this.$t("label.L0138"), data: "userName" },
        { text: this.$t("label.L0016"), data: "telNum" },
        { text: this.$t("label.L0017"), data: "branchCode" },
      ],
      checkResetCurrent: true,
    };
  },
  setup() {
    const {
      refetchData,
      getUserList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      // filter,
      maintable,
      // searchType,
      deleteFunction,
      createFunction,
      updateFunction,
      getregLocalDate,
      getmodLocalDate,
    } = userInterface();

    return {
      refetchData,
      getUserList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      // filter,
      maintable,
      // searchType,
      deleteFunction,
      createFunction,
      updateFunction,
      getregLocalDate,
      getmodLocalDate,
    };
  },
  methods: {
    doFilter() {
      if (this.sortDirection == "A") {
        this.items = this.adminTypeItems;
      } else if (this.sortDirection == "U") {
        this.items = this.userTypeItems;
      } else {
        this.items = this.copyItmes;
      }
      this.checkResetCurrent = this.filter == this.tempfilter;
      this.filter = this.tempfilter;
    },
    addUser() {
      this.propTitle = this.$t("label.L0143");
      this.propType = "create";
      this.$refs.create.createpopup();
    },
    updateUser(data) {
      this.propTitle = this.$t("label.L0137");
      this.propType = "update";
      this.$refs.update.updatepopup(data);
    },
    deleteRow(data) {
      this.$swal({
        heightAuto: false,
        title: this.$t("message.M0006"),
        icon: "info",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: this.$t("button.B0001"),
        cancelButtonText: this.$t("button.B0002"),
        reverseButtons: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await this.deleteFunction(data.item.userId);
          if (response.success) {
            await this.refreshFunction();
          } else {
            this.$swal({
              heightAuto: false,
              title: response.error.message,
              icon: "info",
              confirmButtonText: this.$t("button.B0001"),
              allowEscapeKey: false,
              allowOutsideClick: false,
              customClass: {
                confirmButton: "btn btn-primary ml-1",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
    async createEvent(data) {
      await this.createFunction(data).then(async (res) => {
        if (res.success) {
          await this.$refs.create.hideModal();
          this.filter = "";
          this.tempfilter = "";
          await this.refreshFunction();
        } else {
          await this.$refs.create.showErrorModal(res.error);
        }
      });
    },
    async updateEvent(data) {
      data.regDate = this.getregLocalDate[data.userId];
      data.modificationDate = this.getmodLocalDate[data.userId];
      await this.updateFunction(data).then(async (res) => {
        if (res.success) {
          await this.$refs.update.hideModal();
          await this.refreshFunction();
        } else {
          await this.$refs.update.showErrorModal(res.error);
        }
      });
    },
    async refreshFunction() {
      this.items = await this.getUserList();
      this.copyItmes = [...this.items];

      this.adminTypeItems = [];
      this.copyItmes.forEach((each) => {
        if (each.userAuth == "A") {
          this.adminTypeItems.push(each);
        }
      });
      this.userTypeItems = [];
      this.copyItmes.forEach((each) => {
        if (each.userAuth == "U") {
          this.userTypeItems.push(each);
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      if (!this.checkResetCurrent) {
        this.currentPage = 1;
        this.checkResetCurrent = true;
      }
    },
  },
  async created() {
    this.refreshFunction();
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.getUserList();
    },
  },
};
</script>
<style scoped>
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.input-group-merge {
  margin-left: -13px;
}
.addBtn {
  margin-right: -13px;
}
</style>
